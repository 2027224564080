import React, { useState, useEffect } from 'react'
import './App.css';

function App() {

  console.log("jasmine webcamgirls")
  const [onlineUsernamesArr, setOnlineUsernamesArr] = useState([])
  const [isLoaded, setIsLoaded] = useState(false)

  let getCamgirls = async function(){

      // get approved usernames
      let url = 'https://s3.amazonaws.com/slimandbusty.com/json/jasmineApprovedOnline.json?ut=' + (+new Date())
      let arr = await fetch(url).then(res => res.json())
      setOnlineUsernamesArr(arr);
      setIsLoaded(true)

    //   let getNumCamgirls = 80
    //   url = "https://wmcdpt.com/api/model/feed?siteId=jasmin&psId=bustyshots&psTool=213_1&psProgram=revs&campaignId=&category=girl&limit=" + getNumCamgirls + "&imageSizes=320x240&imageType=any&showOffline=0&onlyFreeStatus=0&extendedDetails=1&responseFormat=json&performerId=&subAffId={SUBAFFID}&accessKey=939cffbd6f4d7e627b7b1c7e2b45de82&legacyRedirect=1&filters=big-breast&customOrder=most_popular";
    //   let json = await fetch(url).then(res => res.json())
    //
    // console.log("fetch res status:", json['status'], "errorCode:", json["errorCode"])
    // console.log("json", json['data'])
    //
    //   let onlineUArr = []
    //   for(let i in json['data']['models']) {
    //     let obj = json['data']['models'][i]
    //     console.log(i, "obj", obj)
    //     onlineUArr.push(obj['performerId'])
    //   }
    //   console.log("onlineUArr", onlineUArr)
    //   let onlineAppUArr = []
    //   for(let u of onlineUArr){
    //     for(let obj of appUsersArr) {
    //       if (u === obj['username']) {
    //         onlineAppUArr.push(obj)
    //       }
    //     }
    //   }
    //   console.log("onlineApprovedUsers #", onlineAppUArr.length)
    //   console.log("onlineApprovedUsers", onlineAppUArr)
      //setOnlineUsernamesArr(onlineAppUArr)

    }

  useEffect(() => {
    if (!isLoaded) {
      getCamgirls();
    }
  }, [isLoaded]);

  if (!isLoaded) {
    return null;
  } else {

    console.log("C", onlineUsernamesArr)

    return (

      <div className="camgirlscont">
        <h4><a href="https://slimandbusty.com">SlimAndBusty</a> camgirls online!</h4>
        {onlineUsernamesArr.map((obj, i) => {
          return <div className="camgirlcont" key={obj['username']}
                      dangerouslySetInnerHTML={{ __html: "<a target='_blank' href='" + obj['link'] + "'><img src='" + obj['imgSrc'] + "' class='thumb'></a><br><a target='_blank' href='" + obj['link'] + "'>" + obj['username'] + "</a>" }}/>
        })}
        <div className="clearBoth"/>
        <h4><a href="https://slimandbusty.com">SlimAndBusty.com</a></h4>
      </div>

    );

  }

}

export default App;
